import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { DetailHeader, DetailStart, DetailFaq } from '@/components/Support/index'
const Index = () => {
  return (
    <Layout  menuBlack={true}>
      <SEO page="Support" />
      <DetailHeader pro='beampro' />
      <DetailStart  manualsLink='/support/XREAL_Beam_Pro_User_Manuals' />
      <DetailFaq type='beampro' />

    </Layout>
  )
}

export default Index